import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const AboutGrid = () => {
  return (
    <section className="about-grid-one">
      <div className="relative w-screen flex justify-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 w-screen lg:w-1000 gap-x-20 gap-y-8 lg:gap-y-28 -mt-12 sm:-mt-16 lg:mt-0 lg:pt-28 pb-16 md:pb-36 z-30">
          <div className="flex flex-col justify-center items-start lg:items-center order-2 lg:order-2 px-4 lg:px-0 pb-12 lg:pb-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8 ">
                <FormattedMessage id="about_history_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="about_history_science_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="about_history_science_paragraph" />
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end order-1 lg:order-2 lg:relative px-4 lg:px-0">
            <div className="max-h-[16rem] sm:h-80 sm:max-h-full w-full sm:w-120 rounded-md overflow-hidden lg:absolute lg:-top-40 lg:right-0 shadow-3xl">
              <StaticImage
                src={"../../images/backgrounds/skylab.jpg"}
                placeholder="blurred"
                layout="constrained"
                width={720}
                height={480}
                loading="eager"
                alt="dtu skylab building"
              />
            </div>
          </div>

          <div className="flex justify-end order-3 lg:relative px-4 lg:px-0">
            <div className="max-h-[16rem] sm:h-80 sm:max-h-full w-full sm:w-120 rounded-md overflow-hidden lg:absolute lg:bottom-8 lg:left-0 shadow-3xl">
              <StaticImage
                src={"../../images/backgrounds/windmill_2.jpg"}
                placeholder="blurred"
                layout="constrained"
                width={720}
                height={480}
                loading="eager"
                alt="windmills on a field"
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-start lg:items-center order-4 px-4 lg:px-0 pb-12 lg:pb-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8">
                <FormattedMessage id="about_name_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="about_name_origin_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="about_name_origin_paragraph" />
                </p>
              </div>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="about_name_meaning_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="about_name_meaning_paragraph" />
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start lg:items-center order-6 lg:order-5 px-4 lg:px-0">
            <div className="max-w-500">
              <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2 w-full md:pl-8">
                <FormattedMessage id="about_partners_heading" />
              </h2>
              <div className="md:pl-8 md:border-l md:border-gray-100 mt-6">
                <h3 className="text-base font-medium text-gray-700 w-full ">
                  <FormattedMessage id="about_partners_title" />
                </h3>
                <p className="text-sm font-light text-gray-500 mt-3 w-full ">
                  <FormattedMessage id="about_partners_paragraph" />
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end order-5 lg:order-6 lg:relative px-4 lg:px-0">
            <div className="w-full h-full sm:h-80 sm:w-120 grid grid-cols-2 gap-y-10 lg:gap-y-0 gap-x-16 bg-blueish-400 rounded-md px-10 py-10 md:px-20 md:py-20 lg:absolute lg:-bottom-48 lg:right-0 shadow-3xl">
              <div className="w-8 lg:w-10 mt-2 mx-auto opacity-100">
                <StaticImage
                  src={"../../images/partner_logos/dtu_white_2.png"}
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  alt="DTU logo"
                />
              </div>
              <div className="w-24 lg:w-32 mt-8 lg:mt-10 mx-auto opacity-100">
                <StaticImage
                  src={"../../images/partner_logos/pexapark_white.png"}
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  alt="Pexapark logo"
                />
              </div>
              <div className="w-28 lg:w-36 mt-4 lg:mt-7 mx-auto opacity-100">
                <StaticImage
                  src={"../../images/partner_logos/risika_white.png"}
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  alt="Risika logo"
                />
              </div>

              <div className="w-20 mt-2 lg:w-28 lg:mt-4  mx-auto opacity-100">
                <StaticImage
                  src={"../../images/partner_logos/accura_white.png"}
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  alt="Accura logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutGrid;
