import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const ABoutPrinciples = () => {
  return (
    <section className="about-principles">
      <div className="relative w-screen flex justify-center bg-white overflow-hidden">
        <div className="flex flex-col w-screen lg:w-1000 xl:px-0 py-12 md:pt-16 md:pb-36 z-20 px-4">
          <div className="max-w-3xl mt-4">
            <div className="font-regular text-base py-4 text-blueish-400">
              <FormattedMessage id="about_principles_tag" />
            </div>
            <h2 className="text-2xl md:text-4xl font-medium text-gray-700 py-2">
              <FormattedMessage id="about_principles_heading" />
            </h2>
          </div>
          <div className="max-w-1080 grid grid-cols-1 sm:grid-cols-2 gap-y-16 py-8 mt-4">
            <div className="pl-8 pr-16 border-l border-gray-100">
              <div className="lg:pr-16 relative">
                <div className="w-8 lg:w-10 mb-4">
                  <StaticImage
                    src={"../../images/icons/climate_first.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Cimate first icon"
                  />
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header relative">
                  <FormattedMessage id="about_principles_climate_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="about_principles_climate_text" />
                </p>
              </div>
            </div>
            <div className="pl-8 pr-16 border-l border-gray-100">
              <div className="lg:pr-16 relative">
                <div className="w-12 lg:w-14 mb-4">
                  <StaticImage
                    src={"../../images/icons/transparency.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Transparency icon"
                  />
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header relative">
                  <FormattedMessage id="about_principles_transparency_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="about_principles_transparency_text" />
                </p>
              </div>
            </div>
            <div className="pl-8 pr-16 border-l border-gray-100">
              <div className="lg:pr-16 relative">
                <div className="w-10 lg:w-12 mb-4">
                  <StaticImage
                    src={"../../images/icons/community.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Community icon"
                  />
                </div>
                <h3 className="text-gray-700 text-base font-medium w-full mt-6 lg:mt-0 principle-header relative">
                  <FormattedMessage id="about_principles_innovation_title" />
                </h3>
                <p className="mt-4 text-gray-500 text-sm font-light">
                  <FormattedMessage id="about_principles_innovation_text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ABoutPrinciples;
