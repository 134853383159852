import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";

const AboutTeamBanner = () => {
  return (
    <section className="about-team">
      <div className="w-screen relative">
        <div className="flex flex-col justify-center items-center w-screen pt-12 lg:pt-28 pb-16 bg-[#FAFAFA] px-4 lg:px-0">
          <div className="w-full lg:w-1000">
            <div className="font-regular text-base py-4 text-blueish-400">
              <FormattedMessage id="about_team_tag" />
            </div>
            <h2 className="text-2xl md:text-4xl font-medium py-2 text-gray-700">
              <FormattedMessage id="about_team_heading" />
            </h2>
          </div>
          <div className="w-full lg:w-1000 bg-gradient-to-br from-[#000] to-[#262626] rounded-md flex flex-col justify-center items-center h-full text-white py-12 md:py-20 z-20 mt-12 shadow-3xl">
            <div className="mt-4 px-4 lg:px-12 flex flex-col-reverse md:flex-row justify-between w-full items-start md:items-center">
              <div className="w-full md:w-500 md:pl-8 md:border-l md:border-gray-800 mt-8 md:mt-0 pr-0 md:pr-8 lg:pr-0">
                <h3 className="text-base font-medium text-white w-full">
                  <FormattedMessage id="about_team_title" />
                </h3>
                <p className="text-sm font-light  py-2 mt-2 mb-4">
                  <FormattedMessage id="about_team_paragraph" />
                </p>
                <a
                  className="text-sm ring-1 ring-white rounded-md py-3 h-12 w-full sm:w-72 flex items-center justify-center hover:bg-gray-800"
                  href="https://www.linkedin.com/company/reelenergy/people/"
                >
                  <FaLinkedin className="mr-2" />{" "}
                  <FormattedMessage id="about_team_stay_in_touch" />
                </a>
              </div>
              <div className="w-full md:h-80 md:w-80 rounded-md">
                <StaticImage
                  src={"../../images/people/founding_team.jpg"}
                  placeholder="blurred"
                  layout="constrained"
                  loading="lazy"
                  alt="the founding team"
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1000 mt-16">
            <div className="pt-5 flex flex-col">
              <div>
                <h3 className=" font-medium text-center lg:text-left text-sm text-gray-900 opacity-40 pb-4 lg:pb-8">
                  <FormattedMessage id="partners_heading" />
                </h3>
              </div>
              <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-y-10 lg:gap-y-0 gap-x-16 items-center">
                <div className="w-14 md:w-20 mx-auto flex flex-col justify-center opacity-30 hover:opacity-60">
                  <StaticImage
                    src={"../../images/partner_logos/dtu.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="DTU"
                  />
                </div>
                <div className="w-20 md:w-24 mx-auto flex flex-col justify-center opacity-30 hover:opacity-60">
                  <StaticImage
                    src={"../../images/partner_logos/accura_black.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Accura"
                  />
                </div>
                <div className="w-20 md:w-24 mx-auto flex flex-col justify-center opacity-30 hover:opacity-60">
                  <StaticImage
                    src={"../../images/partner_logos/climate_kic.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="ClimateKIC"
                  />
                </div>
                <div className="w-28 md:w-36 mx-auto flex flex-col justify-center opacity-30 hover:opacity-60">
                  <StaticImage
                    src={"../../images/partner_logos/innovationsfonden.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Innovationsfonden"
                  />
                </div>
                <div className="w-28 md:w-34 mx-auto flex flex-col justify-center opacity-30 hover:opacity-60">
                  <StaticImage
                    src={"../../images/partner_logos/pexapark.png"}
                    placeholder="blurred"
                    layout="constrained"
                    loading="lazy"
                    alt="Pexapark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTeamBanner;
