import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

const AboutHero = () => {
  return (
    <section className="about-hero">
      <div className="w-screen bg-[#0C4157]">
        <div className="flex w-full lg:w-1000 mx-auto px-4 relative min-h-[550px] md:min-h-[700px] z-20">
          <div className="w-full z-20 relative">
            <div className="w-full flex flex-col justify-center h-full">
              <div className="max-w-3xl">
                <div className="font-regular text-base py-4 text-[#1881AD]">
                  <FormattedMessage id="about_hero_tag" />
                </div>
                <h1 className="text-2xl sm:text-4xl md:text-7xl font-bold text-white py-4 leading-tight">
                  <FormattedMessage id="about_hero_heading" />
                </h1>
                <p className="text-sm sm:text-base font-light text-white py-2 mt-2 opacity-80">
                  <FormattedMessage id="about_hero_sub_heading" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
