import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import AboutHero from "../components/about/hero";
import AboutPrinciples from "../components/about/principles";
import AboutGrid from "../components/about/grid";
import AboutTeamBanner from "../components/about/teamBanner";

const About = () => {
  return (
    <Layout isLight={true}>
      <Seo
        title="About"
        description="Electricity that makes a difference – for your business and the climate"
      />
      <AboutHero></AboutHero>
      <AboutGrid></AboutGrid>
      <AboutTeamBanner></AboutTeamBanner>
      <AboutPrinciples></AboutPrinciples>
    </Layout>
  );
};
export default About;
